import React from "react";
import "./homepage.styles.scss";
import { Link } from "react-router-dom";

// Importing Components
import {
  PrimaryHeading,
  AnimatedHeading,
  LineHeading,
} from "../../components/headings/headings.components.jsx";
import {
  PrimaryButton,
  CircleButton,
} from "../../components/buttons/buttons.components.jsx";
import SecondaryCard from "../../components/cards/secondary-card/secondary-card.components.jsx";
import { useScroll } from "../../components/useScroll.js";
import ScrollTop from "../../components/ScrollTop.js";

// Importing Images
import BlogImg from "../../images/blog-cover.jpg";
import Wave from "../../components/Wave.js";

// Animations
import { motion } from "framer-motion";
import {
  pageAnimation,
  titleAnimation,
  scrollReveal,
} from "../../animation.js";

const Homepage = () => {
  const [element, controls] = useScroll();
  const [element1, controls1] = useScroll();

  return (
    <motion.div
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <header className="homepage__header">
        <p>Hi, my name is</p>
        <p className="name">Samir Ansari.</p>
        <p className="short-intro">I build things for the web.</p>
        <p>
          My core experience is in web developement, but I also have a passion
          for design. Undoubtedly, creating a modern website requires the
          combination of design, server technologies, and the layers that user
          interacts with, I believe experince in both design and developement
          allows for making the most optimal and smooth user experience.
        </p>
        <div className="btn-container">
          <PrimaryButton
            path="/contact"
            TextContent="Get in touch!"
            className="prevent-default-anchor-anim"
          />
        </div>
      </header>
      <Wave />
      <section className="projects">
        <PrimaryHeading id="two" TextContent="Some Things I've Built" />
        <div className="projects-cards-container">
          <SecondaryCard
            externalPath="https://packagecontrol.io/packages/Mooon%20Light%20Theme"
            gitPath="https://github.com/developedby-sam/mooon-light"
            title="Mooon Light"
            desc="A minimal dark blue theme for Sublime Text. Soon it will available for VS Code, Atom and more. Hang tight! it's under developement."
            techList={["TypeScript", "Python", "JSON"]}
          />
          <SecondaryCard
            externalPath="https://developedby-sam.github.io/musify/"
            gitPath="https://github.com/developedby-sam/musify"
            title="A Simple Music Player App"
            desc="A single page, simple and minimalistic music player app built with REACT, HTML and CSS"
            techList={["Web-App", "REACT", "CSS"]}
          />
          <SecondaryCard
            externalPath="/space-invaders"
            gitPath="https://github.com/developedby-sam/space-invaders"
            title="Space Invaders"
            desc="A clone of classic sapce shooter game &mdash; Space Invaders &mdash; built with Python and Pygame. 'Bang Bang' &ndash; let's shoot some enemies."
            techList={["Game Developement", "Python", "Pygame"]}
          />
          <SecondaryCard
            externalPath="https://aen-nepal.org/"
            gitPath=""
            title="Astronomy Enthusiasts In Nepal"
            desc="A website build for a non-profit organization working to flourish the love of astronomy among the astronomy enthusiasts in nepalese community. It is built on HTML, SASS, and Vanila Javascript"
            techList={["Web Design/Developement", "Javascript", "SASS"]}
          />
          <SecondaryCard
            externalPath="https://developedby-sam.github.io/cover/"
            gitPath="https://github.com/developedby-sam/cover"
            title="Cover"
            desc="A single page web app for helping me to download latest high quality freestock wallpapers. It is built with Vanilla Javascript, CSS, and Pexel API"
            techList={["Javascript", "CSS", "Pexel API"]}
          />
          <SecondaryCard
            externalPath="https://developedby-sam.github.io/pig-game/"
            gitPath="https://github.com/developedby-sam/pig-game"
            title="Pig Dice Game"
            desc="A simple game &mdash; clone of real pig game &mdash; built with HTML, CSS, and Javascript. Watch out for 1, you may lose the game Let's Roll the dice!!"
            techList={["Javascript", "Python", "JSON"]}
          />
        </div>
        <div className="flex-center btn-container">
          <CircleButton
            path="/work"
            TextContent="All Projects"
            className="prevent-default-anchor-anim"
          />
        </div>
      </section>
      <motion.section
        className="about"
        variants={scrollReveal}
        ref={element}
        animate={controls}
        initial="hidden"
      >
        <PrimaryHeading id="three" TextContent="About Me" />
        <div className="about-container">
          <div className="description">
            <p>
              I am a student and a web developer. Though, I was curious about
              tech and computers from early age, my interest in web development
              started back when I learned to hack (kind of!) Google's hompage
              using chrome inspector - turns out hacking google taught me a lot
              about HTML and CSS!
            </p>
            <p>
              Fast-forward to today, I build successful website that are fast,
              easy to use, and build with best practices: I love to make a
              better web.
            </p>
            <p>
              I als love documenting my process of learning &mdash; making and
              solving mistakes (even silly ones) &mdash; and write down in my
              blog posts. So, if you want to learn about those and a lot more
              then check out my blogs.
            </p>
          </div>
          <div className="img-container">
            <img src={BlogImg} alt="blog-img" />
          </div>
        </div>
      </motion.section>
      <section className="scrolling-text">
        <AnimatedHeading
          id="right"
          className="italic"
          TextContent="MUSIC - SPORTS - GAMING - WORKOUTS - TRAVEL"
        />
        <AnimatedHeading
          id="left"
          TextContent="FASHION - PEACE - TECH - COMMUNITY - EXPLORE"
        />
        <AnimatedHeading
          id="right"
          TextContent="TRAVEL - GAMING - WORKOUTS - SPORTS - MUSIC"
        />
        <AnimatedHeading
          id="left"
          className="italic"
          TextContent="EXPLORE - COMMUNITY - TECH - PEACE - FASHION"
        />
      </section>
      <motion.section
        className="contacts"
        variants={scrollReveal}
        ref={element1}
        animate={controls1}
        initial="hidden"
      >
        <Link to="/contact" className="hover-link prevent-default-anchor-anim">
          <PrimaryHeading id="four" TextContent="Let's Create &ensp; ➙" />
          <LineHeading />
        </Link>
        <div className="contacts-container">
          <div className="contact-links">
            <div className="social-links">
              <h3>Social</h3>
              <Link
                to={{
                  pathname: "https://twitter.com/developedbysam",
                }}
                target="_blank"
              >
                Twitter
              </Link>
              <Link
                to={{
                  pathname: "https://www.instagram.com/developedbysam",
                }}
                target="_blank"
              >
                Instagram
              </Link>
              <Link
                to={{
                  pathname: "https://www.facebook.com/im.ansarisamir",
                }}
                target="_blank"
              >
                Facebook
              </Link>
              <Link
                to={{
                  pathname: "https://www.linkedin.com/in/developedbysam",
                }}
                target="_blank"
              >
                LinkedIn
              </Link>
            </div>
            <div classname="social-links">
              <h3>Say Hi!</h3>
              <Link
                to={{
                  pathname:
                    "mailto:info@developedbysam.com?Subject=Hello%20User",
                }}
                target="_blank"
              >
                contact@developedbysam.com
              </Link>
            </div>
          </div>
        </div>
      </motion.section>
      <ScrollTop />
    </motion.div>
  );
};

export default Homepage;
