import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// Importing Styles and Components
import "./workspage.styles.scss";
import { LineHeading } from "../../components/headings/headings.components.jsx";
import FeatherIcon from "feather-icons-react";
import ScrollTop from "../../components/ScrollTop.js";

// Importing Animation Components
import {
  sliderContainer,
  pageAnimation,
  fade,
  slider,
} from "../../animation.js";

// Importing Images
import mooonLight from "../../images/works/mooon-light.png";
import spaceInvaders from "../../images/works/space-invaders.png";
import musify from "../../images/works/musify.png";
import beatMaker from "../../images/works/beat-maker.png";
import cover from "../../images/works/cover.png";
import elenaJoy from "../../images/works/elena-joy.png";
import pigGame from "../../images/works/pig-game.png";
import portfolioWeb from "../../images/works/portfolio-website.png";
import travelly from "../../images/works/travelly.png";

const Workspage = () => {
  return (
    <motion.div
      className="page-animation"
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={sliderContainer}>
        <motion.div className="frame frame1" variants={slider}></motion.div>
        <motion.div className="frame frame2" variants={slider}></motion.div>
        <motion.div className="frame frame3" variants={slider}></motion.div>
        <motion.div className="frame frame4" variants={slider}></motion.div>
      </motion.div>
      <div className="workspage__header">
        <header className="workspage__header-primary">
          <motion.h1 variants={fade}>
            Some of my <br /> works
          </motion.h1>
        </header>
        <LineHeading
          className="workspage__header-secondary"
          TextContent="Recent Projects"
        />
      </div>
      <motion.div variants={fade} className="workspage__works">
        {/* Mooo Light Theme */}
        <div className="workspage__works__item">
          <div className="workspage__works__item-left">
            <h3>Mooon Light Theme</h3>
            <p>
              A minimal, dark blue theme for Sublime Text. Soon it will be
              available for VS Code, Atom, and more. Available on{" "}
              <Link
                to={{
                  pathname:
                    "https://www.packagecontrol.io/packages/Mooon%20Light%20Theme",
                }}
                target="_blank"
              >
                {" "}
                Package Control{" "}
              </Link>
              , and soon on{" "}
              <Link
                to={{
                  pathname:
                    "https://www.packagecontrol.io/packages/Mooon%20Light%20Theme",
                }}
                target="_blank"
              >
                Visual Studio Marketplace{" "}
              </Link>
              , and
              <Link
                to={{
                  pathname:
                    "https://www.packagecontrol.io/packages/Mooon%20Light%20Theme",
                }}
                target="_blank"
              >
                Atom Package Manager.
              </Link>
            </p>
            <ul className="tech-list">
              <li>Sublime Text</li>
              <li>TypeScript</li>
              <li>Python</li>
              <li>JSON</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/mooon-light",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://www.packagecontrol.io/packages/Mooon%20Light%20Theme",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right">
            <div className="background bg-purple-cyan">
              <div className="img-container">
                <img src={mooonLight} alt="project-mooon-light" />
              </div>
            </div>
          </div>
        </div>
        {/* Space Invaders Game */}
        <div className="workspage__works__item inverted-item">
          <div className="workspage__works__item-left inverted-left">
            <h3>Space Invaders</h3>
            <p>
              A clone of classic sapce shooter game &mdash; Space Invaders
              &mdash; built with Python and Pygame. 'Bang Bang' &ndash; let's
              shoot some enemies.
            </p>
            <ul className="tech-list">
              <li>Game Developement</li>
              <li>Python</li>
              <li>Pygame</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/space-invaders",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "/space-invaders",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right inverted-right">
            <div className="background bg-cyan-green">
              <div className="img-container">
                <img src={spaceInvaders} alt="project-spaceInvaders" />
              </div>
            </div>
          </div>
        </div>
        {/* Musify */}
        <div className="workspage__works__item">
          <div className="workspage__works__item-left">
            <h3>Musify &mdash; A Simple Music Player</h3>
            <p>
              A single page, simple and minimalistic music player web-app built
              with REACT, HTML and CSS
            </p>
            <ul className="tech-list">
              <li>Web App</li>
              <li>REACT</li>
              <li>CSS</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/musify",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "https://developedby-sam.github.io/musify/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right">
            <div className="background bg-yellow-pink">
              <div className="img-container">
                <img src={musify} alt="project-musify" />
              </div>
            </div>
          </div>
        </div>
        {/* Cover Website */}
        <div className="workspage__works__item inverted-item">
          <div className="workspage__works__item-left inverted-left">
            <h3>Cover &mdash; Free Stock Photos</h3>
            <p>
              A single page web app for helping me to download latest high
              quality freestock wallpapers. It is built with Vanilla Javascript,
              CSS, and Pexel API
            </p>
            <ul className="tech-list">
              <li>Javascript</li>
              <li>CSS</li>
              <li>Pexel Api</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/cover",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "https://developedby-sam.github.io/cover/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right inverted-right">
            <div className="background bg-yellow-green">
              <div className="img-container">
                <img src={cover} alt="project-cover" />
              </div>
            </div>
          </div>
        </div>
        {/* Beat Maker */}
        <div className="workspage__works__item">
          <div className="workspage__works__item-left">
            <h3>Beat Maker &mdash; Let's make some noise</h3>
            <p>
              A single page web app that can produce different instrumental
              sound in a fun way. It is build with Javascript, HTML, and CSS.
            </p>
            <ul className="tech-list">
              <li>HTML</li>
              <li>CSS</li>
              <li>Javascript</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/beat-maker",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "https://developedby-sam.github.io/beat-maker/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right">
            <div className="background bg-yellow-pink">
              <div className="img-container">
                <img src={beatMaker} alt="project-beatMaker" />
              </div>
            </div>
          </div>
        </div>
        {/* Portfolio Template */}
        <div className="workspage__works__item inverted-item">
          <div className="workspage__works__item-left inverted-left">
            <h3>Portfolio &mdash; Template</h3>
            <p>
              A web template which can be used to showcase your portfolio and
              interests with some cool animations. It is built with CSS, HTML,
              and Javascript.
            </p>
            <ul className="tech-list">
              <li>Javascript</li>
              <li>CSS</li>
              <li>HTML</li>
              <li>BarbaJS</li>
              <li>GSAP</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/travel-project",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "https://developedby-sam.github.io/travel-project/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right inverted-right">
            <div className="background bg-orange-pink">
              <div className="img-container">
                <img src={portfolioWeb} alt="project-portfolioWeb" />
              </div>
            </div>
          </div>
        </div>
        {/* Elena Joy */}
        <div className="workspage__works__item">
          <div className="workspage__works__item-left">
            <h3>Elena joy &mdash; Photography</h3>
            <p>
              A single page website which can be used as a photography portfolio
              to showcase the amzing looking photos you took. It is built with
              Vanilla Javascript, SASS, and HTML
            </p>
            <ul className="tech-list">
              <li>Vanilla Javascript</li>
              <li>SASS</li>
              <li>HTML</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname:
                    "https://github.com/developedby-sam/fashion-website",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://developedby-sam.github.io/fashion-website/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right">
            <div className="background bg-purple-pink-yellow">
              <div className="img-container">
                <img src={elenaJoy} alt="project-elenaJoy" />
              </div>
            </div>
          </div>
        </div>
        {/* Pig Dice Game */}
        <div className="workspage__works__item inverted-item">
          <div className="workspage__works__item-left inverted-left">
            <h3>Pig &mdash; Dice Game</h3>
            <p>
              A simple game &mdash; clone of real pig game &mdash; built with
              HTML, CSS, and Javascript. Watch out for 1, you may lose the game.
              Let's Roll the dice!!
            </p>
            <ul className="tech-list">
              <li>HTML</li>
              <li>CSS</li>
              <li>Vanilla Javascript</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/pig-game",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "https://developedby-sam.github.io/pig-game/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right inverted-right">
            <div className="background bg-green-pink">
              <div className="img-container">
                <img src={pigGame} alt="project-pigGame" />
              </div>
            </div>
          </div>
        </div>
        {/* Travelly */}
        <div className="workspage__works__item">
          <div className="workspage__works__item-left">
            <h3>Travelly &mdash; A Travel Website</h3>
            <p>
              A travell website which can help me to book my perfect travelling
              experience (Nope! it is extremly simple implementation). It is
              designed with Figma and built with HTML and CSS
            </p>
            <ul className="tech-list">
              <li>HTML</li>
              <li>CSS</li>
              <li>Figma</li>
            </ul>
            <div className="project-links">
              <Link
                to={{
                  pathname: "https://github.com/developedby-sam/Travelly",
                }}
                target="_blank"
              >
                <FeatherIcon icon="github" />
              </Link>
              <Link
                to={{
                  pathname: "https://developedby-sam.github.io/Travelly/",
                }}
                target="_blank"
              >
                <FeatherIcon icon="external-link" />
              </Link>
            </div>
          </div>
          <div className="workspage__works__item-right">
            <div className="background bg-pink-skyblue">
              <div className="img-container">
                <img src={travelly} alt="project-travelly" />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <ScrollTop />
    </motion.div>
  );
};

export default Workspage;
