import React from "react";
// global styles
import GlobalStyle from "./components/GlobalStyles.js";
// importing components
import Navbar from "./components/nav/navbar.components.jsx";
import Footer from "./components/footer/footer.components.jsx";

// importing pages
import HomePage from "./pages/homepage/homepage.components.jsx";
import Workspage from "./pages/workspage/workspage.components.jsx";
import Aboutpage from "./pages/aboutpage/aboutpage.components.jsx";
import Blogspage from "./pages/blogspage/blogspage.components.jsx";
import Contactpage from "./pages/contactpage/contactpage.components.jsx";

import SpaceInvaders from "./pages/SpaceInvaders.js";
import NotFound from "./pages/NotFound.js";

// Importing blog articles
import coding101 from "./components/blog-articles/coding101.components.jsx";
import HowtoInstallFishShell from "./components/blog-articles/install-fish-shell.components.jsx";

import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

function App() {
  const location = useLocation();
  return (
    <div className="App" style={{ position: "relative" }}>
      <GlobalStyle />
      <Navbar />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact component={HomePage} />
          <Route path="/work" component={Workspage} />
          <Route path="/about" component={Aboutpage} />
          <Route path="/blogs" exact component={Blogspage} />
          <Route path="/contact" component={Contactpage} />
          <Route path="/space-invaders" component={SpaceInvaders} />
          {/* Blog Articles */}
          <Route path="/blogs/coding101" component={coding101} />
          <Route
            path="/blogs/how-to-install-fish-shell"
            component={HowtoInstallFishShell}
          />
          <Route component={NotFound} />
        </Switch>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
