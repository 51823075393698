import React from "react";
import styled from "styled-components";
import { Container } from "../components/Utility.js";

const NotFound = () => {
  return (
    <StyledNotFound>
      <p>Sorry!! We couldn't find what you were searching for.</p>
    </StyledNotFound>
  );
};

const StyledNotFound = styled(Container)`
  height: 40vh;
  p {
    font-size: 5rem;
    font-weight: 300;
  }
`;

export default NotFound;
