import React from "react";
import { motion } from "framer-motion";

// Importing styles and animation
import "./headings.styles.scss";
import { lineAnimation } from "../../animation.js";

// PRIMARY HEADING
export const PrimaryHeading = ({ id, TextContent }) => {
  return (
    <div className="primary-heading">
      <h2 id={id}>{TextContent}</h2>
    </div>
  );
};

// ANIMATED HEADING
export const AnimatedHeading = ({ id, className, TextContent }) => {
  return (
    <div className="animatedHeading-container">
      <h1 id={id} className={`animatedHeading ${className}`}>
        {TextContent}
      </h1>
    </div>
  );
};

// LINE HEADING
export const LineHeading = ({ TextContent }) => {
  return (
    <div className="line-heading">
      <motion.div className="line" variants={lineAnimation}></motion.div>
      {TextContent}
    </div>
  );
};
