import React from "react";

// importing styles and components
import "./blog-directory.styles.scss";
import BlogCard from "../cards/blog-card/blog-card.components.jsx";
import CardsData from "../CardsData";

const BlogDirectory = () => {
  const { card1, card2 } = CardsData;
  console.log(card1);
  return (
    <div className="blog-directory">
      <BlogCard
        img={card1.imageUrl}
        title={card1.title}
        publishedDate={card1.publishedDate}
        readTime={card1.readTime}
        description={card1.description}
        articleLink={card1.articleLink}
      />

      <BlogCard
        img={card2.imageUrl}
        title={card2.title}
        publishedDate={card2.publishedDate}
        readTime={card2.readTime}
        description={card2.description}
        articleLink={card2.articleLink}
      />
    </div>
  );
};

export default BlogDirectory;
