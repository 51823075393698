const CardsData = {
  card1: {
    imageUrl: "https://i.ibb.co/xHF8W4B/code-starting-guide.jpg",
    title: "The coding guide I wish I had when I started learning to code.",
    publishedDate: "January 23, 2022",
    readTime: "5 minutes, 22 seconds",
    description:
      "Let me tell you one thing. Programming is not easy. Well, nothing’s easy unless you make an effort to make it easy. Note this, “All things are difficult before they are easy,” and it’s true, believe it or not....",
    articleLink: "/blogs/coding101",
  },

  card2: {
    imageUrl: "https://i.ibb.co/KjPkBV0/Installing-Fish-Shell-Thumbnail.jpg",
    title: "How to install fish shell on Ubuntu 20.04.",
    publishedDate: "January 25, 2022",
    readTime: "3 minutes",
    description:
      "In this blog article we are going to learn step-by-step how to install fish shell on ubuntu 20.04 and more....",
    articleLink: "/blogs/how-to-install-fish-shell",
  },
};

export default CardsData;
