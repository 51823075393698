import React from "react";

// Importing styles and components
import "./coding101.styles.scss";
import {
  BlogArticleHeading,
  BlogArticleImg,
  BlogArticleParagraph,
  OrderedList,
  CodeBox,
  SecondaryTitle,
  VideoResponsive,
} from "../blog-utilities/blog-utilities.components";
import { ArticleData } from "../ArticlesData";

const HowtoInstallFishShell = () => {
  const { article2 } = ArticleData;
  return (
    <div className="blogArticle-container">
      <div className="blogArticle">
        <BlogArticleHeading heading={article2.heading} />
        <BlogArticleImg imageUrl={article2.imageUrl} alt="thumbnail1" />
        <div className="blogArticle-text">
          <BlogArticleParagraph text={article2.para1} />
          <BlogArticleParagraph text={article2.para2} />
          <SecondaryTitle text={article2.title1} />
          <CodeBox text={article2.code1} />
          <BlogArticleImg imageUrl={article2.ssUrl[1]} alt="thumbnail2" />
          <SecondaryTitle text={article2.title2} />
          <CodeBox text={article2.code2} />
          <BlogArticleImg imageUrl={article2.ssUrl[1]} alt="thumbnail3" />
          <SecondaryTitle text={article2.title3} />
          <CodeBox text={article2.code3} />
          <SecondaryTitle text={"Customizing fish shell."} />
          <BlogArticleParagraph
            text={
              "Run the code below in your terminal to launch the fish web-based customizing tool."
            }
          />
          <CodeBox text={"fish_config"} />
          <BlogArticleImg imageUrl={article2.ssUrl[2]} alt="thumbnail4" />
          <BlogArticleParagraph />
        </div>
      </div>
    </div>
  );
};

export default HowtoInstallFishShell;
