import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Container } from "../components/Utility.js";
import Video from "../media/space-invaders.mp4";
import poster1 from "../media/poster.png";

const SpaceInvaders = () => {
  return (
    <VideoPlayerBox>
      <p>Space Invaders</p>
      <video preload="auto" controls poster={poster1}>
        <source src={Video} />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <Link
        to={{ pathname: "https://github.com/developedby-sam/space-invaders" }}
        target="_blank"
      >
        Source code ⮋
      </Link>
    </VideoPlayerBox>
  );
};

const VideoPlayerBox = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 68vh;

  p {
    font-size: 4rem;
    font-weight: 300;
    padding: 3rem 0rem;
  }

  video {
    width: 80%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  a {
    font-size: 2rem;
    margin: 4rem 0;
    font-weight: 300;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: #ffae6e;
    transition: all 200ms ease-in;
  }

  a:hover {
    transform: scale(1.1);
  }
`;

export default SpaceInvaders;
