import React from "react";

// Importing styles and components
import "./coding101.styles.scss";
import {
  BlogArticleHeading,
  BlogArticleImg,
  BlogArticleParagraph,
  OrderedList,
  QuestionBox,
  SecondaryTitle,
  VideoResponsive,
} from "../blog-utilities/blog-utilities.components";
import { ArticleData } from "../ArticlesData";

const Coding101 = () => {
  const { article1 } = ArticleData;
  return (
    <div className="blogArticle-container">
      <div className="blogArticle">
        <BlogArticleHeading heading={article1.heading} />
        <BlogArticleImg
          imageUrl={article1.imageUrl}
          alt="coding101-thumbnail"
        />
        <div className="blogArticle-text">
          <BlogArticleParagraph text={article1.para1} />
          <BlogArticleParagraph text={article1.para2} />
          <BlogArticleParagraph text={article1.para3} />
          <BlogArticleParagraph text={article1.para4} />
          <VideoResponsive embedId={"nKIu9yen5nc"} />
          <BlogArticleParagraph text={article1.para5} />
          <BlogArticleParagraph text={article1.para6} />
          <BlogArticleParagraph text={article1.para7} />
          <BlogArticleParagraph text={article1.para8} />
          <BlogArticleParagraph text={article1.para9} />
          <OrderedList listItems={article1.guideList} />
          <BlogArticleParagraph text={article1.para10} />
          <QuestionBox text={article1.question1} />
          <BlogArticleParagraph text={article1.para11} />
          <QuestionBox text={article1.question2} />
          <SecondaryTitle text={article1.title2} />
          <BlogArticleParagraph text={article1.para12} />
          <BlogArticleParagraph text={article1.para13} />
          <BlogArticleParagraph text={article1.para14} />
          <BlogArticleParagraph text={article1.para15} />
          <SecondaryTitle text={article1.title3} />
          <BlogArticleParagraph text={article1.para16} />
          <BlogArticleParagraph text={article1.para17} />
        </div>
      </div>
    </div>
  );
};

export default Coding101;
