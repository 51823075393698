import React from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// Import styles
import "./blog-card.styles.scss";

const BlogCard = ({
  img,
  title,
  publishedDate,
  readTime,
  description,
  articleLink,
}) => {
  return (
    <div className="blogCard">
      <div className="blogCard-img">
        <img src={img} alt={`${img}`} />
      </div>
      <div className="blogCard-text">
        <div className="blogCard-title">{title}</div>
        <div className="blogCard-details">
          <div className="published-date">{publishedDate}</div>
          <div className="read-time">
            <FeatherIcon icon="clock" /> {readTime} read
          </div>
        </div>
        <div className="blogCard-description">{description}</div>
        <Link
          className="btn-readMore prevent-default-anchor-anim"
          to={{
            pathname: `${articleLink}`,
          }}
        >
          Read more.
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
