import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	html {
		@media screen and (max-width: 1200px) {
			font-size: 60%;
		}

		@media screen and (max-width: 1024px) {
			font-size: 58%;
		}

		@media screen and (max-width: 768px) {
			font-size: 56%;
		}

		@media screen and (max-width: 480px) {
			font-size: 54%;
		}

		font-size: 62.5%;
	}

	body {
		width: 100%;
		min-height: 100%;
		overflow-x: hidden;
		-webkit-font-smoothing: antialiased;
		background-color: #0a192f;
		color: #8892b0;
		font-family: 'Inter', sans-serif;
		font-size: 1.6rem;
		line-height: 1.3;	
	}

	section {
		margin: 0 auto;
		padding: 10rem 0;
		display: block;

		/* Media Queries */
		@media screen and (max-width: 768px) {
			padding: 5rem 0;
		}
	}

	a {
		display: inline-block;
		text-decoration: none;
		text-decoration-skip-ink: auto;
		position: relative;
		transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
		color: #64ffda;

		&::after {
			content: "";
			display: block;
			width: 0px;
			height: 1.5px;
			position: relative;
			bottom: 0.3rem;
			background-color: #64ffda;
			transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
			opacity: 0;
		}

		&:hover::after {
			width: 100%;
			opacity: 0.7;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
		color: #ccd6f6;
	}

	.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.prevent-default-anchor-anim {
		&:hover::after {
      		width: 0;
   		 }
	}


	#bigger {
			width: 45rem;
			height: auto;

			@media screen and (max-width: 875px) {
				width: 100%;
			}
		}

	#stretch {
		width: 100%;
		height: auto;
	}

	.mt-xl {
		margin-top: 22rem;

		@media screen and (max-width: 1300px) {
				margin-top: 18rem;
		}

		@media screen and (max-width: 768px) {
			margin-top: 0rem;
		}
	}

	.w-100-md {
		@media screen and (max-width: 1024px) {
			width: 100% !important;
			height: auto !important;
		}
	}

	.w-100-sm {
		@media screen and (max-width: 875px) {
			width: 100% !important;
			height: auto !important;
		}
	}

	.mb-md {
		margin-bottom: 5rem;
	}

	.w-100 {
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.ml-auto {
		margin-left: auto;

		@media screen and (max-width: 1024px) {
			margin: 0 auto;
		}
	}

	@keyframes movein {
  from {
    transform: translateX(0);

  }
  to {
    transform: translateX(-30%);
  }
}

@keyframes moveout {
  from {
    transform: translateX(-30%);

  }
  to {
    transform: translateX(0%);
  }
}
`;

export default GlobalStyle;
