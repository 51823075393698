import React from "react";

// Importing styles and components
import "./blogspage.styles.scss";
import ScrollTop from "../../components/ScrollTop";
import { pageAnimation } from "../../animation";
import { motion } from "framer-motion";
import BlogDirectory from "../../components/blog-directory/blog-directory.components";

const Blogspage = () => {
  return (
    <motion.div
      className="blogspage"
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >
      <div className="blogspage__header">
        <h1 className="flex-center">Blogs.</h1>
      </div>
      <div className="blogspage__blogsGroup">
        <BlogDirectory />
      </div>
      <ScrollTop />
    </motion.div>
  );
};

export default Blogspage;
