import React from "react";
import { Link } from "react-router-dom";

// Import styles
import "./buttons.styles.scss";

// Primary Button
export const PrimaryButton = ({
  path,
  TextContent,
  className,
  ...otherProps
}) => {
  return (
    <Link className={`primary-button ${className}`} to={path} {...otherProps}>
      {TextContent}
    </Link>
  );
};

// Secondary Button
export const SecondaryButton = ({ path, TextContent }) => {
  return (
    <Link className="secondary-button" to={path}>
      {TextContent} <span>&#10230;</span>
    </Link>
  );
};

// Circle Button
export const CircleButton = ({ path, TextContent, ...otherProps }) => {
  return (
    <Link to={path} {...otherProps}>
      <div className="circle-button">
        <p>{TextContent}</p>
      </div>
    </Link>
  );
};

// Custom Button
export const CustomButton = ({ children, ...otherProps }) => {
  return (
    <button className="custom-button" {...otherProps}>
      {children}
    </button>
  );
};
