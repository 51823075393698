import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  padding: 0 10%;

  @media screen and (max-width: 768px) {
    padding: 0 5%;
  }

  .line {
    width: 100%;
    background: #e5e7eb;
    height: 3px;
    margin-bottom: 2rem;
  }
`;

export const UnderlineLink = styled(Link)`
  text-decoration: underline;
  transition: all 200ms ease;
  color: #64ffda;

  &:hover {
    color: transparent;
    -webkit-text-stroke: 1px #64ffda;
  }
`;
