import React from "react";
import { useLocation } from "react-router-dom";

// Importing Styles
import "./footer.styles.scss";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <footer
      className={
        pathname.includes("/blogs") ? "footer blogspage-footer" : "footer"
      }
    >
      developedbysam &copy; 2021
    </footer>
  );
};

export default Footer;
