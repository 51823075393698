import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

// Importing Styles
import "./navbar.styles.scss";

const Navbar = () => {
  const { pathname } = useLocation();
  return (
    <motion.div
      transition={{ duration: 0.75 }}
      initial={{ background: "rgba(17, 34, 64, 0.8)", color: "#8892b0" }}
      animate={{
        background: pathname.includes("/blogs")
          ? "rgb(255, 255, 255)"
          : "rgba(17, 34, 64, 0.8)",
        color: pathname === "/blogs" ? "#444" : "#8892b0",
      }}
      className={
        pathname.includes("/blogs")
          ? "navbar-container blogspage-nav"
          : "navbar-container"
      }
    >
      <h1 id="logo">
        <Link id={"logo"} className="prevent-default-anchor-anim" to="/">
          samiransari
        </Link>
      </h1>
      <ul className="nav-list">
        <li>
          <Link to="/work" className="prevent-default-anchor-anim">
            Work
          </Link>
          <motion.div
            className="line"
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/work" ? "100%" : "0%" }}
          ></motion.div>
        </li>
        <li>
          <Link to="/about" className="prevent-default-anchor-anim">
            About
          </Link>
          <motion.div
            className="line"
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/about" ? "100%" : "0%" }}
          ></motion.div>
        </li>
        <li>
          <Link to="/blogs" className="prevent-default-anchor-anim">
            Blogs
          </Link>
          <motion.div
            className="line blogspage-nav-line"
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{
              width: pathname.includes("/blogs") ? "100%" : "0%",
            }}
          ></motion.div>
        </li>
        <li>
          <Link to="/contact" className="prevent-default-anchor-anim">
            Contacts
          </Link>
          <motion.div
            className="line"
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/contact" ? "100%" : "0%" }}
          ></motion.div>
        </li>
      </ul>
    </motion.div>
  );
};

export default Navbar;
