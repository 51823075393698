import React, { useState } from "react";
import { motion } from "framer-motion";
import FormInput from "../../form-input/form-input.components.jsx";
import { CustomButton } from "../../buttons/buttons.components.jsx";
import { useForm } from "@formspree/react";

// Importing Styles
import "./contact-form.styles.scss";

// Contact Form
export const ContactForm = ({ variants }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [detail, setDetail] = useState("");

  const [state, handleSubmit] = useForm("xvolzbzr");
  if (state.succeeded) {
    return (
      <p className="thanks-msg">
        Thanks for your interest! Will get back to you soon. 😃
      </p>
    );
  }

  return (
    <motion.div className="contact-form" variants={variants}>
      <p>Hello,</p>
      <form onSubmit={handleSubmit}>
        <FormInput
          type="text"
          name="fullname"
          label="My name is"
          placeholder="Enter your name here"
          onChange={(e) => setName(e.target.value)}
          value={name}
          id="fullname"
          required
        />

        <FormInput
          type="email"
          name="email"
          label="Here is my email"
          placeholder="Enter your email here"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          id="email"
          required
        />

        <FormInput
          type="textarea"
          name="detail"
          label="I'm looking for"
          placeholder="Enter your project details here"
          onChange={(e) => setDetail(e.target.value)}
          value={detail}
          id="detail"
          required
        />

        <CustomButton type="submit">Send</CustomButton>
      </form>
    </motion.div>
  );
};
