import React from "react";

// Importing styles
import "./form-input.styles.scss";

const FormInput = ({ label, ...otherProps }) => {
  return (
    <div className="form-input">
      {label ? <label>{label}</label> : null}
      <input className="form-input" {...otherProps} />
    </div>
  );
};

export default FormInput;
